<template>
    <div class="base-info-content">
        <div class="flow-status">
            <div v-for="(item,index) in steps" v-show="index <= current.nowIndex" :key="index" class="stepContent" @click="handlerStep(item, index)" >
                <span :class="{cricle: true, active: index === showActive}">{{ item.name }}</span>
                <span :class="{label:true, active: index === showActive}">{{ item.label }}</span>
                <span v-if="index < current.nowIndex" class="line" />
            </div>
        </div>
        <div v-loading="loading">
            <tableList v-if="showList || !not_has_info" :flow-list="categoriesList" />
            <span v-if="not_has_info && current.key === 'upload_data'" class="empty-list">评估，无资料收集</span>
            <!-- //择校规划与辅助面试 -->
            <div v-if="current.key === 'plan_for_school' && offer_delivery_finished" class="configuration">
                <!-- // 学校名称 -->
                <div class="school-name-tab">
                    <span v-for="item in schoolList" :key="item.name" :class="{schoolName:true, active: activeSchoolName === item.id}" @click="checkSchool(item)" >{{ getSchoolLabel(item) }}</span>
                </div>
                <div v-for="item in schoolList" v-show="activeSchoolName === item.id" :key="item.name" class="school-list">
                    <tableList :flow-list="item.list" />
                    <div class="form-content">
                        <el-form ref="formRef" :model="item" :rules="rules">
                            <el-form-item v-if="item.written_test_time" label="笔试时间" prop="name" >
                                {{ item.written_test_time }}
                            </el-form-item>
                            <el-form-item label="陪同需求(需要香港同事陪同)" prop="methods">
                                <span v-if="item.escort_name" class="font16">是(由[{{ item.escort_name }}]陪同)</span>
                            </el-form-item>
                            <el-form-item v-if="item.interview_time" label="面试时间">
                                {{ item.interview_time }}
                            </el-form-item>
                            <el-form-item label="offer结果">
                                <span v-if="item.offer_result !== null">{{ item.offer_result ? '录取' : ' 落选' }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
            <!-- 校内同步辅导 in_school_guidance -->
            <div v-if="current.key === 'in_school_guidance'" class="order-complete" >
                <!-- 总课时 -->
                <el-form ref="refData" :model="LessonHours" rules="rules" label-width="130px">
                    <el-form-item label="总课时:" prop="total_time" :rules="[{required: true,message: '请选择入职年月',trigger: 'change'}]">
                        <span>{{ LessonHours.total_time }}</span>
                    </el-form-item>
                    <el-form-item label="各课程需求分配:" prop="chinese_time" :rules="[{required: true,message: '请选择入职年月',trigger: 'change'}]">
                        <ul class="ul-content">
                            <li>
                                <span v-for="item in curriculumList" :key="item.name" class="cell">{{ item.name }}</span>
                            </li>
                            <li>
                                <span v-for="(item, index) in curriculumList" :key="item.prop" class="cell">
                                    <span v-if="index === 0">需求分配</span>
                                    <span v-else>{{ LessonHours[item.prop] }}</span>
                                </span>
                            </li>
                        </ul>
                    </el-form-item>
                </el-form>
            </div>
            <!-- // 订单完成 -->
            <div v-if="current.key === 'order_finished'" class="order-success-content">
                <div class="order-label"><span class="red">*</span>确认订单完成</div>
                <span class="order-success">√订单已确认完成</span>
            </div>
        </div>
    </div>
</template>
<script>
import tableList from './c/tableList.vue'
import {getProgressList, uploadFiles, evaluation, schoolGuidance, planning} from '@/api/hkEducation.js'
export default {
    components:{
        tableList
    },
    data() {
        return {
            showActive: 0,
            current:{
                key:'',
                label: '',
                index: 0
            },
            wordList: ['A','B','C','D','E'],
            steps: [
                // { name: 'A', label: '上传资料', status: false },
                // { name: 'B', label: '学情评估 ', status: false },
                // { name: 'C', label: '规划择校与辅助面试 ', status: true },
                // { name: 'D', label: '上传资料', status: false },
                // { name: 'E', label: '订单完成', status: false },
            ],
            categoriesList: [],
            flowListFour: [
                { title: '报名费凭证上传（付款人、金额）', list: [] },
                { title: '报名证明图片留底（线下邮寄报名资料留底/线上报名成功截图/学校确认函回执）', list: [] },
            ],
            activeSchoolName: 0,
            selectShoolList: [],
            schoolList: [],
            //
            curriculumList: [
                {
                    name: '',
                    prop: '3',
                },
                {
                    name: '语文课',
                    prop: 'chinese_time',
                },
                {
                    name: '数学课',
                    prop: 'math_time',
                },
                {
                    name: '英语课',
                    prop: 'english_time',
                },
                {
                    name: '面试课',
                    prop: 'interview_time',
                }
            ],
            LessonHours:{
                total_time: '111',
                chinese_time:'222',
                math_time: '333',
                english_time: '444',
                interview_time: '555'
            },
            loading:false,
            not_has_info: false,
            school_select_finished:false,
            offer_delivery_finished:false
        }
    },
    computed: {
        showList() {
            const arr = ['upload_data', 'evaluation', 'plan_for_school']
            if(this.current.key === 'plan_for_school') {
               return arr.includes(this.current.key) && this.school_select_finished
            }
            return arr.includes(this.current.key)
        }
    },
    mounted() {
        // this.getStatus()
        this.init()
    },
    methods: {
        getStatus() {
            this.active = this.steps.findIndex(item => item.status === true)
        },
        stepIcon(index) {
            return this.steps[index].name
        },
        initForm() {
            return {
                id: new Date(),
                name: '',
                methods: '',
                flowList: [{ title: '报名费凭证上传（付款人、金额）', list: [{}, {}] }, { title: '报名证明图片留底（线下邮寄报名资料留底/线上报名成功截图/学校确认函回执）', list: [{}, {}] }],

            }
        },
        getSchoolLabel(item){
            //计算当前序数前面的学校出现的次数
            const shcoolName = item.school_name
            const schoolNo = item.school_no
            if(schoolNo > 1) {
                return  `${shcoolName}(${schoolNo})`
            }else {
                return `${shcoolName}`
            }
        },
        checkSchool(i) {
            this.activeSchoolName = i.id
        },
        async init() {
            const params = {
                order_id: this.$route.params.id
            }
            const {data} = await getProgressList(params)
            const {current, progress} = data
            const index = progress.findIndex(item => item.key === current.key)
            this.current = {...current, nowIndex: index}
            this.showActive = index
            this.steps = progress.map((item,index) =>{
                return {
                    name: this.wordList[index],
                    ...item
                }
            })
            this.loading = true
            switch(current.key){
                case 'upload_data':
                    // 上传资料
                    await this.getUploadData()
                    break;
                case 'evaluation':
                    // 学情评估
                    await this.getEvaluation()
                    break;
                case 'plan_for_school':
                    // 上规划择校与辅助面试
                    await this.getPlaning()
                    break;
                case 'in_school_guidance':
                    // 校内同步辅导
                    await this.getSchoolGuidance()
                    break;
                case 'order_finished':
                // 订单完成
                break;
            }
            this.loading = false

        },
        setMapIDList(list, id){
            let cateMaps = {}
            list.length && list.forEach(item => {
                if (item.file_cate_id in cateMaps) {
                    cateMaps[item.file_cate_id] = [...cateMaps[item.file_cate_id], item]
                } else {
                    cateMaps[item.file_cate_id] = [item]
                }
            })
            return cateMaps[id]
        },
        async getUploadData(){
            try{
            const {data} = await uploadFiles({order_id: this.$route.params.id})
                const {categories, files} = data
                this.not_has_info = data.code === 'not_has_info'
                this.categoriesList = categories.length && categories.map(item => {
                    return {
                        ...item,
                        list:this.setMapIDList(files, item.id)
                    }
                })
            }catch(err){
                console.log(err)
            }
        },
        async getEvaluation(){
            try{
                const {data} = await evaluation({order_id: this.$route.params.id})
                const {categories, files} = data
                this.categoriesList = categories.length && categories.map(item => {
                    return {
                        ...item,
                        list:this.setMapIDList(files, item.id)
                    }
                })
            }catch(err){
                console.log(err)
            }
        },
        async getSchoolGuidance(){
            try{
                const {data} = await schoolGuidance({order_id: this.$route.params.id})
                this.LessonHours = data
            }catch(err){
                    console.log(err)
            }

        },
        async getPlaning(){
            try{
                const {data} = await planning({order_id: this.$route.params.id})
                const {categories, files, schools = [], school_select_finished, offer_delivery_finished} = data
                this.school_select_finished = school_select_finished
                this.offer_delivery_finished = offer_delivery_finished
                this.schoolList = schools.map(item =>{
                    return {
                        ...item,
                        list:[
                            {name: '报名费凭证上传（付款人、金额）',list: item.reg_fee_files},
                            {name: '报名证明图片留底（线下邮寄报名资料留底/线上报名成功截图/学校确认函回执）',list: item.reg_cert_files}
                        ]
                    }
                })
                // this,flowListFour = [{ title: '报名费凭证上传（付款人、金额）', list: schools }, { title: '报名证明图片留底（线下邮寄报名资料留底/线上报名成功截图/学校确认函回执）', list: [{}, {}] }],
                this.categoriesList = categories.length && categories.map(item => {
                    return {
                        ...item,
                        list:this.setMapIDList(files, item.id)
                    }
                })
                this.activeSchoolName = schools[0] && schools[0].id
            }catch(err){
                console.lolg(err)
            }finally{
                this.loading = false
            }
        },
        async handlerStep(item, index) {
            this.not_has_info = true,
            this.current.key = item.key
            this.loading = true
            this.showActive = index
            switch(item.key){
                case 'upload_data':
                    // 上传资料
                    await this.getUploadData()
                    break;
                case 'evaluation':
                    // 学情评估
                    await this.getEvaluation()
                    break;
                case 'plan_for_school':
                    // 上规划择校与辅助面试
                    await this.getPlaning()
                    break;
                case 'in_school_guidance':
                    // 校内同步辅导
                    await this.getSchoolGuidance()
                    break;
                case 'order_finished':
                // 订单完成
                break;
            }
            this.loading = false
        }
    }
}
</script>
<style lang="less" scope>
.content {
    padding: 15px;
    display: flex;
}

.flow-status {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding-left: 100px;
    margin-bottom: 30px;
}

.school-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .school {
        height: 39px;
        line-height: 39px;
        padding: 0 25px;
        background: #40AAFF;
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        .cancel {
            font-size: 16px;
            position: absolute;
            right: 3px;
            top: 2px;
        }
    }
}

.form-content {
    margin-bottom: 20px;
}

.base-info-content {
    overflow-y: scroll;
}
.ul-content {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
        margin: 0;
        padding: 0;
        height: 42px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .cell {
        display: inline-block;
        width: 20%;
        height: 42px;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        text-align: center;
        line-height: 42px;
        padding: 0 3px;
    }
}
.stepContent {
    width: 20%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .cricle {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background: #9D9D9D;
        color: #FFFFFF;
        text-align: center;
        line-height: 35px;
        font-size: 22px;
        flex-shrink: 0;
    }
    .cricle.active{
        background: #40AAFF !important;
    }
    .label {
        width: 100%;
        padding: 5px;
        color: #000000;
        // font-size: 24px;
        position: absolute;
        bottom: -20px;
        left: calc(-50% + 17px);
        text-align: center;
        height: 22px;
    }
    .label.active {
        color: #40AAFF !important;
    }
    .line {
        width: 100%;
        height: 3px;
        background: #9D9D9D;
    }
}
.order-label {
    font-size: 15px;
    margin-bottom: 15px;
    .red{
        color: red;
        padding-right: 5px;
        padding-top: -10px;
    }
}

.order-success-content {
    padding-left: 70px;
}
.order-success {
    color: #40AAFF;
    font-size: 24px;
}
.school-name-tab {
}
.schoolName {
    display: inline-block;
    padding: 5px 15px;
    display: inline-block;
    border: 1px solid #40AAFF;
    color: #40AAFF;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.schoolName.active {
    background:#40AAFF ;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.empty-list {
    color: #D40000;
}
.font16 {
    font-size: 14px;
}
</style>
