import api from './axios';

// 查看进度
export const getProgressList = (params) => api.get(`/business/educational/progress`, { params })

// 查看上传资料
export const uploadFiles = (params) => api.get(`/business/educational/upload_files`, { params })

// 查看学情评估
export const evaluation = (params) => api.get(`/business/educational/evaluation`, { params })

// 查看上传资料
export const schoolGuidance = (params) => api.get(`/business/educational/school_guidance`, { params })

// 择校规划与辅助面试
export const planning = (params) => api.get(`/business/educational/planning`, { params })