<script>
export default {
    name: 'UploadContentList',
    props: {
        status: {
            type: Boolean,
            default: true,
        },
        flowList: {
            type: Array,
            default: () => {
                return () => []
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        // 预览图片
        ylImage(row) {
            if (row.file_url) {
                window.open(this.$utils.toOss(row.file_url))
            }
        },
        delImage(index, row) {
            const indexList = this.flowList[index].list.findIndex(item => item.id === row.id)
            this.flowList[index].list.splice(indexList, 1)
            this.$emit('update:list', this.flowList)
        },
    },
}
</script>

<template>
    <div>
        <div v-for="(item, index) in flowList" :key="index" class="list-item">
            <div class="required">
                {{ item.name }}
                <el-tooltip v-if="item.titleTool" class="item" effect="dark" content="Top Left 提示文字"
                            placement="top-start"
                >
                    <i class="el-icon-warning" />
                </el-tooltip>
            </div>
            <!-- <div v-if="status" class="operation-content">
                <slot />
            </div> -->
            <el-table v-loading="loading" :data="item.list" stripe style="width: 100%; margin-top: 20px;"
                      max-height="720px" border resizable
            >
                <el-table-column type="index" label="序号" width="55" />
                <el-table-column label="项" show-overflow-tooltip>
                    <template #default="{ row }">
                        <el-link v-if="'upd_url' == row.extension" type="primary" :href="row.file_url"
                                 :underline="false" target="_blank"
                        >
                            {{ row.file_name }}
                        </el-link>
                        <div v-else>
                            {{ row.file_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="file_type" label="项类型" width="100" show-overflow-tooltip />
                <el-table-column prop="created_at" label="操作时间" show-overflow-tooltip />
                <el-table-column prop="operator_name" label="操作人" show-overflow-tooltip />
                <el-table-column label="操作" width="100">
                    <template #default="{ row }">
                        <el-button v-if="!['upd_text', 'upd_url'].includes(row.extension)" type="text"
                                   @click="ylImage(row)"
                        >
                            预览
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<style lang="less" scoped>
.required {
    position: relative;
    margin-left: 10px;
    // font-size: 20px;
    // font-weight: 700;
}
.operation-content {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.list-item {
    margin-bottom: 25px;
}
</style>
